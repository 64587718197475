import { inject } from 'vue'
import type { ShopwareContext } from '@shopware-pwa/composables-next'
import type { ApiClient } from '#shopware'

function _useShopwareContext() {
  const { localeProperties } = useI18n()

  const shopwareContext = inject<ShopwareContext | null>('shopware', null)

  const apiClient = inject<ApiClient>('apiClient')

  if (!shopwareContext || !apiClient) {
    console.error('[Error][Shopware] API Client is not provided.')
    throw new Error('Shopware or apiClient')
  }

  if (localeProperties.value.languageId) {
    apiClient.defaultHeaders.apply({
      'sw-language-id': localeProperties.value.languageId,
    })
    console.log('sw-language-id', localeProperties.value.languageId)
  }

  return {
    apiClient,
    devStorefrontUrl: shopwareContext.devStorefrontUrl,
  }
}

export const useShopwareContext = createSharedComposable(_useShopwareContext)
